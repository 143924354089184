<template>
    <label v-if="titleElement"
           :class="titleClass"
           :for="id"
    >
        {{ titleElement }}
        <span v-if="isRequired"
              class="text-primary"
        >
            *
        </span>
    </label>

    <p class="mb-0 fst-italic">
        <small v-if="textSmall">{{ textSmall }}</small>
    </p>

    <MultiSelect
        v-model="internalValue"
        :max-height="optionsMaxHeightDisplay"
        :options="options"
        :options-limit="optionsLimitToDisplay"
        :searchable="true"
        :label="displayOption"
        :loading="isLoading"
        :track-by="selectOptionUnique"
        :multiple="isMultiple"
        :placeholder="placeholder"
        :selectLabel="selectLabelMsg"
        :deselectLabel="deselectLabelMsg"
        :selectedLabel="selectedLabelMsg"
        :showNoResults="showNoResults"
        :id="id"
        :data-automation="`select-${ id }`"
        :internal-search="false"
        @search-change="searchChangeFunction"
    >
        <template #noResult>
            <span>{{ noResultsMsg }}</span>
        </template>
    </MultiSelect>

    <span v-if="textBelow" :class="classBelow">{{ textBelow }}</span>
</template>

<script>
import { computed } from 'vue';
import { formProps } from '@/mixins';

export default {
    name: 'VF-multi-select',
    props: {
        deselectLabelMsg: {
            type: String,
            required: true,
            default: ''
        },
        displayOption: {
            type: String,
            required: true,
            default: ''
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isMultiple: {
            type: Boolean,
            required: true,
            default: true
        },
        noResultsMsg: {
            type: String,
            required: false,
            default: ''
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        optionsLimitToDisplay: {
            type: Number,
            required: false,
            default: 30
        },
        optionsMaxHeightDisplay: {
            type: Number,
            required: false,
            default: 600
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        searchChangeFunction: {
            type: Function,
            required: false,
            default: () => []
        },
        selectLabelMsg: {
            type: String,
            required: true,
            default: ''
        },
        selectOptionUnique: {
            type: String,
            required: true,
            default: ''
        },
        selectedLabelMsg: {
            type: String,
            required: true,
            default: ''
        },
        showNoResults: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mixins: [
        formProps
    ],
    emits: [
        'update:modelValue'
    ],
    setup( props, { emit }) {
        const internalValue = computed({
            get() {
                return props.modelValue;
            },
            set( val ) {
                emit('update:modelValue', val);
            }
        });

        return {
            internalValue
        };
    }
};
</script>

<style>
.multiselect {
    border: none !important;
}

.multiselect__tags {
    border: 1px solid #ced4da !important;
    line-height: 1.5;
}

.multiselect__placeholder {
    padding: 0 !important;
}
</style>
