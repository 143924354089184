<template>
    <div class="dropdown text-center">
        <span @click.stop="onToggleDropdownMenuFunction( rowId, $event )"
              class="btn btn-link p-0 text-black"
              data-automation="button-dropdown-menu"
        >
            <i class="fas fa-ellipsis-v"></i>
        </span>

        <ul class="dropdown-menu position-fixed shadow"
            :style="{ top: dropdownMenuPosition.top, left: dropdownMenuPosition.left }"
            :class="{ show: isDropdownMenuOpenFunction( rowId )}"
            :ref="el => onSetDropdownMenuContainerRefFunction( el, rowId )"
            @click.stop
        >
        <!-- BASIC BUTTONS -->
            <VFDropdownItem
                icon="fas fa-eye"
                data-automation="button-dropdown-menu-view"
                :action="() => onViewFunction( row )"
                :text="$t('general.table.button_view', { property: entityName })"
            />

            <VFDropdownItem
                icon="fas fa-edit"
                data-automation="button-dropdown-menu-edit"
                :action="() => onEditFunction( row )"
                :text="$t('general.table.button_edit', { property: entityName })"
            />
        <!-- END BASIC BUTTONS -->

        <!-- TRANSITION BUTTONS -->
            <VFDropdownItem
                v-if="showActiveOption"
                icon="fas fa-check"
                data-automation="button-dropdown-menu-active"
                :action="() => onChangeElementStateFunction( row, activeAcronym )"
                :text="$t('general.table.button_active', {
                    property: entityName
                })"
            />

            <VFDropdownItem
                v-if="showApproveOption"
                icon="fas fa-check"
                data-automation="button-dropdown-menu-approve"
                :action="() => onChangeElementStateFunction( row, pendingAcronym )"
                :text="$t('general.table.button_approve', {
                    property: entityName
                })"
            />

            <VFDropdownItem
                v-if="showDeactivateOption"
                icon="fas fa-ban"
                data-automation="button-dropdown-menu-deactivate"
                :action="() => onChangeElementStateFunction( row, inactiveAcronym )"
                :text="$t('general.table.button_inactive', {
                    property: entityName
                })"
            />

            <VFDropdownItem
                v-if="showDeleteOption"
                icon="fas fa-trash"
                data-automation="button-dropdown-menu-delete"
                :action="() => onDeleteElementFunction( row )"
                :text="$t('general.table.button_delete', {
                    property: entityName
                })"
            />

            <VFDropdownItem
                v-if="showDisapproveOption"
                icon="fas fa-times"
                data-automation="button-dropdown-menu-disapprove"
                :action="() => onChangeElementStateFunction( row, underConstructionAcronym )"
                :text="$t('general.table.button_disapprove', {
                    property: entityName
                })"
            />
        <!-- END TRANSITION BUTTONS -->
        </ul>
    </div>
</template>
<script>
import { VFDropdownItem } from '@/components';

export default {
    name: 'ListViewTableButtons',
    components: {
        VFDropdownItem

    },
    props: {
        activeAcronym: {
            type: String,
            required: true
        },
        dropdownMenuPosition: {
            type: Object,
            required: true
        },
        entityName: {
            type: String,
            required: true
        },
        inactiveAcronym: {
            type: String,
            required: true
        },
        isDropdownMenuOpenFunction: {
            type: Function,
            required: true
        },
        onChangeElementStateFunction: {
            type: Function,
            required: true
        },
        onDeleteElementFunction: {
            type: Function,
            required: true
        },
        onEditFunction: {
            type: Function,
            required: true
        },
        onSetDropdownMenuContainerRefFunction: {
            type: Function,
            required: true
        },
        onToggleDropdownMenuFunction: {
            type: Function,
            required: true
        },
        onViewFunction: {
            type: Function,
            required: true
        },
        pendingAcronym: {
            type: String,
            required: true
        },
        row: {
            type: Object,
            required: true
        },
        rowId: {
            type: String,
            required: true
        },
        showActiveOption: {
            type: Boolean,
            required: true
        },
        showApproveOption: {
            type: Boolean,
            required: true
        },
        showDeactivateOption: {
            type: Boolean,
            required: true
        },
        showDeleteOption: {
            type: Boolean,
            required: true
        },
        showDisapproveOption: {
            type: Boolean,
            required: true
        },
        underConstructionAcronym: {
            type: String,
            required: true
        }
    }
}
</script>
